/* The container */
.container {
  box-sizing: border-box;
  color: #31363d;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue LT W01_41488878", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  -moz-font-feature-settings: "liga" on;
  font-size: 17px !important;
  font-size-adjust: 0.6;
  font-weight: normal !important;
  line-height: 1.75;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 35px;
  padding-right: 15px;
  position: relative;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto !important;
}

/* Hide the browser's default checkbox */
.container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  background-color: #eee;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  display: none;
  position: absolute;
}

/* Style the checkmark/indicator */
.container .checkmark::after {
  border: solid white;
  border-width: 0 3px 3px 0;
  height: 10px;
  left: 9px;
  top: 5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 5px;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark::after {
  display: block;
}

.modal a.close-modal {
  right: 12.5px;
  top: 12.5px;
}

.modal {
  min-width: 50%;
}

#close-cookie-area {
  color: black;
}

#close-cookie-area:hover {
  color: blue;
}

@media (max-width: 960px) {
  .cc-theme-classic .cc-btn:last-child {
    margin-right: 40px !important;
  }
}

@media (max-width: 1183px) {
  #cookies-form {
    display: inline;
  }

  .terms {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .termsandbutton {
    display: block !important;
  }

  .cookie-policy {
    margin-bottom: 10px !important;
    margin-left: 10px !important;
  }

  .cc-compliance {
    padding-top: 10px;
  }
}

.buttonok {
  background-color: rgb(75, 129, 232);
  border-color: transparent;
  color: rgb(255, 255, 255);
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 0.9em;
  font-weight: 700;
  line-height: 1.5em;
}

#more-details-button {
  background-color: transparent;
  box-sizing: border-box;
  font-family: inherit;
  -moz-font-feature-settings: "liga" on;
  font-size: 17px;
  font-weight: normal;
  line-height: 150%;
  text-align: left;
  text-decoration: none;
}

div#cookies-modal p {
  box-sizing: border-box;
  color: #31363d;
  font-family: inherit;
  -moz-font-feature-settings: "liga" on;
  font-size: 17px;
  font-size-adjust: 0.6;
  font-weight: normal;
  line-height: 150%;
  margin: 0 0 11px;
  margin-bottom: 2.1875rem;
  text-align: left;
}

.buttonagree {
  -webkit-appearance: none;
  background: transparent;
  border: solid 1px #007aff;
  border-radius: 0;
  color: #007aff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", Arial, sans-serif, Helvetica;
  font-size: 0.75em;
  font-weight: 600;
  height: 48px !important;
  letter-spacing: 1px;
  line-height: 40px;
  margin: 10px auto;
  outline: none;
  padding: 0 30px;
  text-align: center;
  text-transform: uppercase;
  transition: all ease-in-out 0.2s;
}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}

.bannerlgview {

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 9999;
}

.popupbtns {
  position: relative;

  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  background-color: white;
  padding: 20px;
}

.cookie-box {
  background-color: white;
}

.filler {
  height: 90px;
}

.buttonsdiv {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
}

.scrollable-container {
  height: 150px;
  overflow: auto;
}