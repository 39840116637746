.marquee {
  width: 100%;
  height: 54px;
  border-top: 3px solid #104652;
  background-color: #104652;
  border-bottom: 3px solid #104652;
  overflow: hidden;
  display: flex; 
  align-items: center; 
}

.marquee li {
  color: var(--Base-White, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.marquee__content {
  display: flex;
  list-style: none;
  animation: scrolling 100s linear infinite;
}

.marquee__item {
  flex-shrink: 0;
}

@keyframes scrolling {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}
