.mask {
    position: relative;
    width: 100%;
    margin-top: 128px;
}

.mask::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2));
    z-index: 1;
}

.mask::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2));
    z-index: 1;
}

.content {
    position: relative;
    z-index: 0;
}
