.toggle input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #fff;
  }
.toggle input:checked ~ .block {
    background-color: #48bb78;
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 50px;
    margin: 5px;
  }
  